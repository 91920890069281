// constants/version.ts
var apiVersion = "1.0.23";

// helpers/getEnvVariable.ts
var getEnvVariable = (name) => {
  const variable = process.env[name];
  if (variable === void 0) {
    throw new Error(`Environment variable not found: ${name}`);
  }
  return variable;
};

// helpers/NumberWithComma.ts
var NumberWithComma = (val) => Number(String(val).replace(",", "."));

// helpers/noItemsUndefined.ts
var noItemsUndefined = (...items) => {
  return !items.some((item) => item === void 0);
};

// helpers/getBuildingAndBuildingPartNames.ts
var getBuildingAndBuildingPartNames = (buildingName, buildingPartName, motherBuildingPartName) => `${buildingName} - ` + (motherBuildingPartName !== void 0 && motherBuildingPartName !== "" ? `${motherBuildingPartName} - ` : "") + `${buildingPartName}`;

// helpers/unifyString.ts
var unifyString = (str) => {
  return str.replaceAll(" ", "").normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
};

// helpers/sanitizeString.ts
var sanitizeString = (input) => input.replace(/[&/\\#,;+()$~%.^'":*?<>{}]/g, " ").replace(/\s+/g, " ").trim();

// libs/displaySurface.ts
var displaySurface = (length, width) => {
  switch (width) {
    case 1:
      return `${Math.round(length / 1e4 * 100) / 100}m2`;
      break;
    case 100:
      return `${Math.round(length) / 100}m2`;
    default:
      return `${Math.round(length) / 100}m * ${Math.round(width) / 100}m`;
      break;
  }
};

// libs/displayVolume.ts
var displayVolume = (length, width, height) => {
  if (width === 1 && height === 1) {
    return `${Math.round(length / 1e6 * 1e4) / 1e4}m3`;
  } else if (width === 100 && height === 100) {
    return `${Math.round(length) / 100}m3`;
  } else {
    return `${Math.round(length) / 100}m * ${Math.round(width) / 100}m * ${Math.round(height) / 100}m`;
  }
};

// types/ImageTypes.ts
var ImageTypes = /* @__PURE__ */ ((ImageTypes2) => {
  ImageTypes2["materialInputPicture"] = "materialInputPicture";
  ImageTypes2["buildingPartMap"] = "buildingPartMap";
  return ImageTypes2;
})(ImageTypes || {});

// types/ListName.ts
var ListName = /* @__PURE__ */ ((ListName2) => {
  ListName2["TECHNICAL_INFORMATION"] = "Technical Information";
  ListName2["ECONOMIC_INFORMATION"] = "Economic Information";
  ListName2["DEPOSIT"] = "Deposit";
  ListName2["CONDITIONING"] = "Conditioning";
  ListName2["STORAGE"] = "Storage";
  ListName2["TRANSPORT"] = "Transport";
  ListName2["RECYCLING_TECHNICAL_INFORMATION"] = "Recycling Technical Information";
  ListName2["RECYCLING_ECONOMIC_INFORMATION"] = "Recycling Economic Information";
  return ListName2;
})(ListName || {});

// types/OrganizationType.ts
import { nativeEnum } from "zod";
var OrganizationType = /* @__PURE__ */ ((OrganizationType2) => {
  OrganizationType2["DIAGNOSTIC"] = "Diagnostic";
  OrganizationType2["PROJECT_OWNER"] = "ProjectOwner";
  return OrganizationType2;
})(OrganizationType || {});
var filterOrganizationTypes = (types) => {
  return types.filter((type) => {
    const parseResult = nativeEnum(OrganizationType).safeParse(type);
    return parseResult.success;
  });
};

// types/REPCategories.ts
var REPCategories = /* @__PURE__ */ ((REPCategories2) => {
  REPCategories2["PLASTIC"] = "plastic";
  REPCategories2["WOOD"] = "wood";
  REPCategories2["METAL"] = "metal";
  REPCategories2["GLASS_MINERAL_WOOL"] = "glassMineralWool";
  REPCategories2["ROCK_MINERAL_WOOL"] = "rockMineralWool";
  REPCategories2["QUALITY_1_PLASTER"] = "quality1Plaster";
  REPCategories2["BITUMINOUS_MIXTURE"] = "bituminousMixture";
  REPCategories2["GLASS_JOINERY"] = "glassJoinery";
  REPCategories2["QUALITY_2_PLASTER"] = "quality2Plaster";
  REPCategories2["INERTS_CAT_1"] = "inertsCat1";
  return REPCategories2;
})(REPCategories || {});

// types/ReuseChannelTypes.ts
var ReuseChannelTypes = /* @__PURE__ */ ((ReuseChannelTypes2) => {
  ReuseChannelTypes2["reuse"] = "REUSE";
  ReuseChannelTypes2["reutilisation"] = "REUTILISATION";
  return ReuseChannelTypes2;
})(ReuseChannelTypes || {});

// types/UserGroup.ts
import { z } from "zod";
var UserGroup = /* @__PURE__ */ ((UserGroup2) => {
  UserGroup2["ADMIN"] = "admin";
  UserGroup2["AKIBO_ADMIN"] = "AkiboAdmin";
  UserGroup2["AKIBO_USER"] = "AkiboUser";
  UserGroup2["AKIBO_PROJECT_OWNER"] = "AkiboProjectOwner";
  return UserGroup2;
})(UserGroup || {});
var filterUserGroups = (groups) => {
  return groups.filter((group) => {
    const parseResult = z.nativeEnum(UserGroup).safeParse(group);
    return parseResult.success === true;
  });
};

// types/ageTypes.ts
var AgeTypes = /* @__PURE__ */ ((AgeTypes2) => {
  AgeTypes2["twoYears"] = "twoYears";
  AgeTypes2["betweenTwoAndTen"] = "betweenTwoAndTen";
  AgeTypes2["betweenTenAndFifty"] = "betweenTenAndFifty";
  AgeTypes2["greaterThanFifty"] = "greaterThanFifty";
  return AgeTypes2;
})(AgeTypes || {});
var stringIsAgeTypes = (age) => {
  return Object.values(AgeTypes).includes(age);
};

// types/buildingPartTypes.ts
var BuildingPartType = /* @__PURE__ */ ((BuildingPartType2) => {
  BuildingPartType2["FLOOR"] = "FLOOR";
  BuildingPartType2["BASEMENT"] = "BASEMENT";
  BuildingPartType2["EXTERIOR"] = "EXTERIOR";
  BuildingPartType2["FACADE"] = "FACADE";
  BuildingPartType2["STRUCTURE"] = "STRUCTURE";
  BuildingPartType2["TOITURE"] = "TOITURE";
  return BuildingPartType2;
})(BuildingPartType || {});
var isBuildingPartTypeLevel = (buildingPartType) => buildingPartType === "FLOOR" /* FLOOR */ || buildingPartType === "BASEMENT" /* BASEMENT */;

// types/buildingTypologies.ts
var BuildingTypology = /* @__PURE__ */ ((BuildingTypology2) => {
  BuildingTypology2["INDIVIDUAL_HOUSE"] = "INDIVIDUAL_HOUSE";
  BuildingTypology2["COLLECTIVE_HOUSING"] = "COLLECTIVE_HOUSING";
  BuildingTypology2["SHOPS"] = "SHOPS";
  BuildingTypology2["OFFICES"] = "OFFICES";
  BuildingTypology2["INDUSTRIAL_BUILDING"] = "INDUSTRIAL_BUILDING";
  BuildingTypology2["HEALTH_ESTABLISHMENT"] = "HEALTH_ESTABLISHMENT";
  BuildingTypology2["EDUCATIONAL_ESTABLISHMENT"] = "EDUCATIONAL_ESTABLISHMENT";
  BuildingTypology2["RESTAURANTS"] = "RESTAURANTS";
  BuildingTypology2["SPORTS_LEISURE_BUILDING"] = "SPORTS_LEISURE_BUILDING";
  BuildingTypology2["ICPE"] = "ICPE";
  BuildingTypology2["UNKNOWN"] = "UNKNOWN";
  return BuildingTypology2;
})(BuildingTypology || {});

// types/csvExportType.ts
var CsvExportType = /* @__PURE__ */ ((CsvExportType2) => {
  CsvExportType2["CSTB"] = "cstb";
  return CsvExportType2;
})(CsvExportType || {});

// types/documentStatus.ts
var DocumentStatus = /* @__PURE__ */ ((DocumentStatus2) => {
  DocumentStatus2["CREATING"] = "CREATING";
  DocumentStatus2["CREATED"] = "CREATED";
  DocumentStatus2["FAILED"] = "FAILED";
  return DocumentStatus2;
})(DocumentStatus || {});

// types/documentType.ts
var DocumentType = /* @__PURE__ */ ((DocumentType2) => {
  DocumentType2["REPORT"] = "report";
  DocumentType2["MATERIAL_SHEET"] = "material sheet";
  DocumentType2["GROUPED_MATERIAL_SHEET"] = "grouped material sheet";
  DocumentType2["ZIP_PICTURES"] = "zip picture";
  DocumentType2["PICTURES_CATALOG"] = "pictures catalog";
  DocumentType2["CSV_EXPORT"] = "csv export";
  return DocumentType2;
})(DocumentType || {});

// types/emailTypes.ts
var EmailTypes = /* @__PURE__ */ ((EmailTypes2) => {
  EmailTypes2["CC"] = "cc";
  EmailTypes2["SENDER"] = "sender";
  return EmailTypes2;
})(EmailTypes || {});

// types/inputMethod.ts
var InputMethod = /* @__PURE__ */ ((InputMethod2) => {
  InputMethod2["DIMENSIONS"] = "DIMENSIONS";
  InputMethod2["SURFACE"] = "SURFACE";
  InputMethod2["VOLUME"] = "VOLUME";
  InputMethod2["UNIT"] = "UNIT";
  return InputMethod2;
})(InputMethod || {});

// types/inputUnit.ts
var InputUnit = /* @__PURE__ */ ((InputUnit2) => {
  InputUnit2["METER"] = "METER";
  InputUnit2["CENTIMETER"] = "CENTIMETER";
  InputUnit2["NONE"] = "NONE";
  return InputUnit2;
})(InputUnit || {});

// types/materialEvaluationMethods.ts
import * as yup from "yup";
var MaterialEvaluationMethod = /* @__PURE__ */ ((MaterialEvaluationMethod2) => {
  MaterialEvaluationMethod2["VOLUME"] = "VOLUME";
  MaterialEvaluationMethod2["SURFACE"] = "SURFACE";
  MaterialEvaluationMethod2["LINEAR"] = "LINEAR";
  MaterialEvaluationMethod2["WINDOW"] = "WINDOW";
  MaterialEvaluationMethod2["UNITS"] = "UNITS";
  return MaterialEvaluationMethod2;
})(MaterialEvaluationMethod || {});
var evaluationDimensions = {
  ["LINEAR" /* LINEAR */]: ["length"],
  ["SURFACE" /* SURFACE */]: ["length", "width"],
  ["VOLUME" /* VOLUME */]: ["length", "width", "height"],
  ["WINDOW" /* WINDOW */]: ["height", "width"],
  ["UNITS" /* UNITS */]: []
};
var fieldConfigurations = {
  ["UNITS" /* UNITS */]: {
    fields: {},
    evaluationCalculation: () => 1,
    displayDimensions: () => "",
    getUnitMethod: () => "u"
  },
  ["LINEAR" /* LINEAR */]: {
    fields: {
      length: {
        unit: "cm",
        required: true,
        type: "tel",
        validationSchema: yup.number().required().moreThan(0)
      }
    },
    /**
     * units are in cm
     * functionalUnits are in m
     */
    evaluationCalculation: ({ length }) => length / 100,
    displayDimensions: ({ length }) => `${length / 100}m`,
    getUnitMethod: () => "m"
  },
  ["SURFACE" /* SURFACE */]: {
    fields: {
      length: {
        unit: "cm",
        required: true,
        type: "tel",
        validationSchema: yup.number().required().moreThan(0)
      },
      width: {
        unit: "cm",
        required: true,
        type: "tel",
        validationSchema: yup.number().required().moreThan(0)
      }
    },
    /**
     * units are in cm2
     * functionalUnits are in m2
     */
    evaluationCalculation: ({ width, length }) => width * length / 1e4,
    displayDimensions: ({ width, length }) => displaySurface(length, width),
    getUnitMethod: () => "m2"
  },
  ["WINDOW" /* WINDOW */]: {
    fields: {
      height: {
        unit: "cm",
        required: true,
        type: "tel",
        validationSchema: yup.number().required().moreThan(0)
      },
      width: {
        unit: "cm",
        required: true,
        type: "tel",
        validationSchema: yup.number().required().moreThan(0)
      }
    },
    /**
     * units are in cm2
     * functionalUnits are in m2
     */
    evaluationCalculation: ({ width, height }) => width * height / 1e4,
    displayDimensions: ({ width, height }) => displaySurface(height, width),
    getUnitMethod: () => "m2"
  },
  ["VOLUME" /* VOLUME */]: {
    fields: {
      length: {
        unit: "cm",
        required: true,
        type: "tel",
        validationSchema: yup.number().required().moreThan(0)
      },
      width: {
        unit: "cm",
        required: true,
        type: "tel",
        validationSchema: yup.number().required().moreThan(0)
      },
      height: {
        unit: "cm",
        required: true,
        type: "tel",
        validationSchema: yup.number().required().moreThan(0)
      }
    },
    /**
     * units are in cm2
     * functionalUnits are in m2
     */
    evaluationCalculation: ({ width, length, height }) => width * length * height / 1e6,
    displayDimensions: ({ width, height, length }) => displayVolume(length, width, height),
    getUnitMethod: () => "m3"
  }
};
var getMaterialEvaluationMethodUnit = (evaluationMethod) => {
  const unitMethod = fieldConfigurations[evaluationMethod].getUnitMethod();
  return unitMethod;
};

// types/operationTypes.ts
var OperationTypes = /* @__PURE__ */ ((OperationTypes2) => {
  OperationTypes2["DEMOLITION"] = "DEMOLITION";
  OperationTypes2["SIGNIFICANT_RENOVATION"] = "SIGNIFICANT_RENOVATION";
  OperationTypes2["BOTH"] = "BOTH";
  OperationTypes2["DECONTAMINATION"] = "DECONTAMINATION";
  OperationTypes2["OTHER"] = "OTHER";
  OperationTypes2["NONE"] = "NONE";
  OperationTypes2["UNKNOWN"] = "UNKNOWN";
  return OperationTypes2;
})(OperationTypes || {});

// types/wasteCategories.ts
var WasteCategory = /* @__PURE__ */ ((WasteCategory2) => {
  WasteCategory2["DD"] = "DD";
  WasteCategory2["DI"] = "DI";
  WasteCategory2["DNIND"] = "DNIND";
  WasteCategory2["DEEE"] = "DEEE";
  WasteCategory2["DEA"] = "DEA";
  return WasteCategory2;
})(WasteCategory || {});
var stringIsWasteCategory = (category) => {
  return Object.values(WasteCategory).includes(category);
};
var wasteCategoryNames = {
  DD: "D\xE9chets dangereux",
  DI: "D\xE9chets inertes",
  DNIND: "D\xE9chets non dangereux non inertes",
  DEEE: "D\xE9chets d'Equipements Electriques et Electroniques",
  DEA: "DEA"
};

// types/ReuseActorChalandise.ts
import { nativeEnum as nativeEnum2 } from "zod";
var ReuseActorChalandise = /* @__PURE__ */ ((ReuseActorChalandise2) => {
  ReuseActorChalandise2["DEPARTEMENTAL"] = "D\xE9partemental";
  ReuseActorChalandise2["REGIONAL"] = "R\xE9gional";
  ReuseActorChalandise2["NATIONAL"] = "National";
  ReuseActorChalandise2["INTERNATIONAL"] = "International";
  ReuseActorChalandise2["UNKNOWN"] = "";
  return ReuseActorChalandise2;
})(ReuseActorChalandise || {});
var filterReuseActorChalandise = (type) => {
  const parseResult = nativeEnum2(ReuseActorChalandise).safeParse(type);
  if (parseResult.success) {
    return parseResult.data;
  }
  return "" /* UNKNOWN */;
};

// types/ReuseActorPlatformType.ts
import { nativeEnum as nativeEnum3 } from "zod";
var ReuseActorPlatformType = /* @__PURE__ */ ((ReuseActorPlatformType2) => {
  ReuseActorPlatformType2["PHYSICAL"] = "PHYSICAL";
  ReuseActorPlatformType2["DIGITAL"] = "DIGITAL";
  ReuseActorPlatformType2["BOTH"] = "BOTH";
  ReuseActorPlatformType2["UNKNOWN"] = "";
  return ReuseActorPlatformType2;
})(ReuseActorPlatformType || {});
var filterReuseActorPlatformType = (type) => {
  const parseResult = nativeEnum3(ReuseActorPlatformType).safeParse(type);
  if (parseResult.success) {
    return parseResult.data;
  }
  return "" /* UNKNOWN */;
};

// libs/translateListName.ts
var translateListName = (listName) => {
  switch (listName) {
    case "Conditioning" /* CONDITIONING */:
      return "Conditionnement";
    case "Deposit" /* DEPOSIT */:
      return "D\xE9pose";
    case "Economic Information" /* ECONOMIC_INFORMATION */:
      return "Informations \xE9conomiques";
    case "Recycling Economic Information" /* RECYCLING_ECONOMIC_INFORMATION */:
      return "Informations \xE9conomiques";
    case "Storage" /* STORAGE */:
      return "Stockage";
    case "Technical Information" /* TECHNICAL_INFORMATION */:
      return "Informations techniques";
    case "Recycling Technical Information" /* RECYCLING_TECHNICAL_INFORMATION */:
      return "Informations techniques";
    case "Transport" /* TRANSPORT */:
      return "Transport";
  }
};

// libs/getDisplayableWeight.ts
var getDisplayableWeight = (weightInKg, addUnit = false) => {
  if (weightInKg < 50 && weightInKg > 0) {
    return "< 50kg";
  } else {
    return (Math.round(weightInKg / 10) / 100).toFixed(2) + (addUnit ? "t" : "");
  }
};

// libs/isInCognitoGroup.ts
var isAdmin = (userCognitoGroups) => userCognitoGroups.some((group) => group === "admin" /* ADMIN */);
var isAkiboUser = (userCognitoGroups) => userCognitoGroups.some((group) => group === "AkiboUser" /* AKIBO_USER */);
var isAkiboAdmin = (userCognitoGroups) => userCognitoGroups.some((group) => group === "AkiboAdmin" /* AKIBO_ADMIN */);
var isAkiboProjectOwner = (userCognitoGroups) => userCognitoGroups.some((group) => group === "AkiboProjectOwner" /* AKIBO_PROJECT_OWNER */);

// rds/rdsDataService.ts
import { RDSData } from "@aws-sdk/client-rds-data";

// sharedConfig.ts
var projectName = "afleya";
var LAMBDAS_NODE_OPTIONS = "--enable-source-maps --stack-trace-limit=1000";
var defaultEnvironment = "dev";
var httpApiAuthorizerId = {
  "Fn::ImportValue": "${self:custom.projectName}-HttpApiAuthorizerId-${self:provider.stage}"
};
var eventBusArn = "arn:aws:events:#{AWS::Region}:#{AWS::AccountId}:event-bus/afleya";
var eventBusArnImport = {
  "Fn::ImportValue": "${self:custom.projectName}-EventBusArn-${self:provider.stage}"
};
var eventBusName = "afleya";
var esbuildConfig = {
  packager: "yarn",
  bundle: true,
  minify: false,
  sourcemap: true,
  target: "node20",
  exclude: [],
  platform: "node",
  mainFields: ["module", "main"],
  concurrency: 1
};
var sharedEnvsConfig = {
  dev: {
    profile: "afleya-developer",
    apiGatewayCorsAllowedOrigins: [
      "http://localhost:3000",
      "http://localhost:3001"
    ],
    adminApiGatewayCorsAllowedOrigins: [
      "http://localhost:3000",
      "http://localhost:3001"
    ],
    domain: "",
    apiDomain: "",
    adminDomain: "",
    adminApiDomain: "",
    cloudfrontCertificateArn: "",
    apiCertificateArn: "",
    httpApiAuthorizerId,
    eventBusName,
    eventBusArn,
    eventBusArnImport
  },
  staging: {
    profile: "",
    apiGatewayCorsAllowedOrigins: ["https://staging.akibo.fr"],
    adminApiGatewayCorsAllowedOrigins: ["https://admin-staging.akibo.fr"],
    domain: "staging.akibo.fr",
    apiDomain: "api-staging.akibo.fr",
    adminDomain: "admin-staging.akibo.fr",
    adminApiDomain: "api-admin-staging.akibo.fr",
    cloudfrontCertificateArn: "arn:aws:acm:us-east-1:226997612997:certificate/844fcc4c-8698-4933-ba7f-a772a37f574c",
    apiCertificateArn: "arn:aws:acm:eu-west-3:226997612997:certificate/a01c7c8c-dbd1-429d-85a4-30b65cc5d772",
    httpApiAuthorizerId,
    eventBusName,
    eventBusArn,
    eventBusArnImport
  },
  production: {
    profile: "",
    apiGatewayCorsAllowedOrigins: ["https://www.akibo.fr"],
    adminApiGatewayCorsAllowedOrigins: ["https://admin.akibo.fr"],
    domain: "www.akibo.fr",
    apiDomain: "api.akibo.fr",
    adminDomain: "admin.akibo.fr",
    adminApiDomain: "api-admin.akibo.fr",
    cloudfrontCertificateArn: "arn:aws:acm:us-east-1:077261660769:certificate/ddf1c3aa-0d38-41d1-afcb-e7ad3320f811",
    apiCertificateArn: "arn:aws:acm:eu-west-3:077261660769:certificate/c89ce2e5-a3ad-4aa0-8317-5429cf63dd51",
    httpApiAuthorizerId,
    eventBusName,
    eventBusArn,
    eventBusArnImport
  }
};
var sharedProviderConfig = {
  name: "aws",
  runtime: "nodejs20.x",
  region: "eu-west-3",
  profile: "${self:custom.sharedEnvsConfig.${self:provider.stage}.profile}",
  // Used to point to the right AWS account
  stage: `\${opt:stage, '${defaultEnvironment}'}`,
  // Doc: https://www.serverless.com/framework/docs/providers/aws/guide/credentials/
  environment: {
    AWS_NODEJS_CONNECTION_REUSE_ENABLED: "1",
    NODE_OPTIONS: LAMBDAS_NODE_OPTIONS
  }
};
var sharedRDSConfig = {
  apiVersion: "2018-08-01",
  region: "eu-west-3"
};

// rds/rdsDataService.ts
var rdsDataService = new RDSData(sharedRDSConfig);

// rds/executeStatementCommand.ts
var executeStatementCommand = async ({
  sql,
  parameters
}) => {
  let maxLimit = void 0;
  while (true) {
    try {
      return await executeStatementCommandWithLimit({
        sql,
        parameters,
        maxLimit
      });
    } catch (error) {
      console.warn(`Error while executing command with limit ${maxLimit}`);
      if (maxLimit !== void 0) {
        maxLimit = Math.round(maxLimit / 2);
        if (maxLimit < 10) {
          throw new Error("unable to recover items");
        }
      } else {
        maxLimit = 4e3;
      }
    }
  }
  return void 0;
};
var executeStatementCommandWithLimit = async ({
  sql,
  parameters,
  maxLimit
}) => {
  const resourceArn = getEnvVariable("AURORA_DATABASE_ARN");
  const secretArn = getEnvVariable("AURORA_DATABASE_SECRET_ARN");
  if (maxLimit === void 0) {
    const { records: records2 } = await rdsDataService.executeStatement({
      secretArn,
      resourceArn,
      sql,
      parameters
    });
    return records2;
  }
  let limit = maxLimit;
  let offset = 0;
  const sqlWithLimit = `${sql} LIMIT :limit OFFSET :offset`;
  const records = [];
  while (limit) {
    const parametersWithLimit = [
      {
        name: "limit",
        value: { longValue: limit }
      },
      {
        name: "offset",
        value: { longValue: offset }
      }
    ];
    if (parameters) {
      parametersWithLimit.push(...parameters);
    }
    const { records: chunks } = await rdsDataService.executeStatement({
      secretArn,
      resourceArn,
      sql: sqlWithLimit,
      parameters: parametersWithLimit
    });
    if (chunks) {
      records.push(...chunks);
    }
    if (chunks === void 0 || chunks.length < limit) {
      limit = 0;
    } else {
      offset += limit;
    }
  }
  return records;
};

// schemas/entities/REPKPIsEntity.ts
import { nativeEnum as nativeEnum4, number as number2, object } from "zod";
var REPKPIsSchema = object({
  category: nativeEnum4(REPCategories),
  weight: number2().nonnegative(),
  result: number2().nonnegative(),
  economy: number2()
});

// schemas/headersSchema.ts
var headersSchema = {
  type: "object",
  properties: {
    authorization: { type: "string" }
  },
  required: ["authorization"]
};

// schemas/siretSchema.ts
import { string } from "zod";
var siretSchema = string().regex(/^[0-9]{14}$/);
export {
  AgeTypes,
  BuildingPartType,
  BuildingTypology,
  CsvExportType,
  DocumentStatus,
  DocumentType,
  EmailTypes,
  ImageTypes,
  InputMethod,
  InputUnit,
  LAMBDAS_NODE_OPTIONS,
  ListName,
  MaterialEvaluationMethod,
  NumberWithComma,
  OperationTypes,
  OrganizationType,
  REPCategories,
  REPKPIsSchema,
  ReuseActorChalandise,
  ReuseActorPlatformType,
  ReuseChannelTypes,
  UserGroup,
  WasteCategory,
  apiVersion,
  defaultEnvironment,
  displaySurface,
  displayVolume,
  esbuildConfig,
  evaluationDimensions,
  eventBusName,
  executeStatementCommand,
  fieldConfigurations,
  filterOrganizationTypes,
  filterReuseActorChalandise,
  filterReuseActorPlatformType,
  filterUserGroups,
  getBuildingAndBuildingPartNames,
  getDisplayableWeight,
  getEnvVariable,
  getMaterialEvaluationMethodUnit,
  headersSchema,
  isAdmin,
  isAkiboAdmin,
  isAkiboProjectOwner,
  isAkiboUser,
  isBuildingPartTypeLevel,
  noItemsUndefined,
  projectName,
  rdsDataService,
  sanitizeString,
  sharedEnvsConfig,
  sharedProviderConfig,
  sharedRDSConfig,
  siretSchema,
  stringIsAgeTypes,
  stringIsWasteCategory,
  translateListName,
  unifyString,
  wasteCategoryNames
};
