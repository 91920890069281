import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Stack,
  TextField,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  title: string;
  contentText: string | ReactElement;
  confirmText?: string;
  confirmDuplicateText?: string;
  onAgree: (() => Promise<void>) | (() => void);
  onDisagree: () => void;
  agreeing?: boolean;
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      children: JSX.Element;
    },
    ref: React.Ref<unknown>,
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

export const ConfirmDialog = ({
  title,
  contentText,
  confirmText,
  onAgree,
  onDisagree,
  agreeing,
  confirmDuplicateText,
}: Props): JSX.Element => {
  const [text, setText] = useState('');

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={onDisagree}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {contentText}
        </DialogContentText>
        {Boolean(confirmText) && (
          <Stack direction="column" spacing={1} sx={{ marginTop: '1em' }}>
            <FormattedMessage
              id="confirm-dialog-component.confirmText"
              values={{ confirmText: confirmText }}
            />
            <TextField
              variant="outlined"
              placeholder={confirmText}
              onChange={event => setText(event.target.value)}
            />
          </Stack>
        )}
        {Boolean(confirmDuplicateText) && (
          <Stack direction="column" spacing={1} sx={{ marginTop: '1em' }}>
            <FormattedMessage
              id="confirm-dialog-component.confirmDuplicateText"
              values={{ confirmDuplicateText: confirmDuplicateText }}
            />
            <TextField
              variant="outlined"
              placeholder={confirmDuplicateText}
              onChange={event => setText(event.target.value)}
            />
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          data-test="confirmDialogDisagree"
          id="disagree-dialog-button"
          color="primary"
          variant="contained"
          onClick={onDisagree}
        >
          <FormattedMessage id="confirm-dialog-component.no" />
        </Button>
        <Button
          data-test="confirmDialogAgree"
          id="agree-dialog-button"
          color="primary"
          variant="contained"
          disabled={
            (confirmText !== undefined && text !== confirmText) ||
            (confirmDuplicateText !== undefined &&
              text !== confirmDuplicateText) ||
            agreeing === true
          }
          onClick={() => {
            void onAgree();
          }}
        >
          {agreeing === true ? (
            <CircularProgress size={20} />
          ) : (
            <FormattedMessage id="confirm-dialog-component.yes" />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
